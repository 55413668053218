<template>
    <v-container fluid>
        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="320"
            v-if="selectedTicket.name != ''"
        >
            <v-card>
                <v-card-title class="headline">Delete Ticket?</v-card-title>
                <v-card-text>
                    <p>
                        You are about to delete the ticket
                        <strong>{{ selectedTicket.name }}</strong
                        >. This will remove the ticket record as well as any
                        associations with users and projects. This cannot be
                        undone. Are you sure you want to delete this ticket?
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="deleteDialog = false"
                        >{{ $t('cancel') }}</v-btn
                    >
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="deleteTicket"
                        >{{ $t('delete') }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'TicketsGrid',
    components: {},
    props: {
        keywords: {
            type: null,
            required: true,
        },
    },
    data: () => ({
        itemsPerPageOptions: [4, 8, 12],
        itemsPerPage: 8,
        deleteDialog: false,
        selectedTicket: {},
    }),
    methods: {
        promptForDelete(ticket) {
            this.selectedTicket = ticket
            this.deleteDialog = true
        },
        deleteTicket() {
            this.deleteDialog = false
        },
        editTicket(ticket) {
            this.$emit('edit-ticket', ticket)
        },
    },
}
</script>
