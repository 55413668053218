<template>
    <v-card>
        <v-card-title>
            <h3>Tickets</h3>
            <v-spacer />
            <v-text-field
                v-model="keywords"
                append-icon="mdi-magnify"
                label="Search..."
                clearable
                single-line
                hide-details
            />
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        color="primary"
                        @click="newTicketForm()"
                        fab
                        dark
                    >
                        <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>New Ticket</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <tickets-grid :keywords.sync="keywords" @edit-ticket="editTicket" />
        </v-card-text>
        <v-dialog v-model="ticketFormDialog" persistent max-width="600">
            <ticket-form
                :ticket.sync="formTicket"
                @ticket-form-saved="closeTicketForm()"
                @ticket-form-closed="closeTicketForm()"
            />
        </v-dialog>
    </v-card>
</template>
<script>
import TicketsGrid from '@/views/tickets/TicketsGrid'
import TicketForm from '@/views/tickets/TicketForm'
export default {
    name: 'home',
    components: {
        'tickets-grid': TicketsGrid,
        'ticket-form': TicketForm,
    },
    data: () => ({
        keywords: '',
        ticketFormDialog: false,
        formTicket: {},
    }),
    methods: {
        newTicketForm: function() {
            this.formTicket = {}
            this.ticketFormDialog = true
        },
        closeTicketForm: function() {
            this.ticketFormDialog = false
        },
        editTicket: function(ticket) {
            this.formTicket = ticket
            this.ticketFormDialog = true
        },
    },
}
</script>
