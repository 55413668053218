<template>
    <v-form ref="form">
        <v-card>
            <v-card-title>
                <span class="headline">Ticket Form</span>
            </v-card-title>
            <v-card-text>
                <v-progress-linear v-if="formLoading" indeterminate />
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="formData.name"
                                :label="$t('ticket_name')"
                                :disabled="formLoading"
                                required
                            />
                        </v-col>
                        <v-col cols="12" sm="6"> </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="formData.address_one"
                                :disabled="formLoading"
                                :label="$t('address_line_one')"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="formData.address_two"
                                :disabled="formLoading"
                                :label="$t('address_line_two')"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="formData.city"
                                :label="$t('city')"
                                :disabled="formLoading"
                            />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="formData.state"
                                :label="$t('state')"
                                :disabled="formLoading"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="formData.zipcode"
                                :label="$t('zipcode')"
                                :disabled="formLoading"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    text
                    @click="$emit('ticket-form-closed')"
                    :disabled="formLoading"
                    >{{ $t('close') }}</v-btn
                >
                <v-btn
                    v-if="formData.id"
                    color="primary darken-1"
                    text
                    :disabled="formLoading"
                    @click="updateTicket()"
                    >{{ $t('save') }}</v-btn
                >
                <v-btn
                    v-if="!formData.id"
                    color="primary darken-1"
                    text
                    :disabled="formLoading"
                    @click="createTicket()"
                    >{{ $t('create') }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
export default {
    name: 'TicketForm',
    props: {
        ticket: {
            type: Object,
            default: () => ({
                id: '',
                name: '',
                address_one: '',
                address_two: '',
                city: '',
                state: '',
                zipcode: '',
                users: [],
                main_contact: {},
            }),
        },
    },
    data: () => ({
        isValid: true,
        mutation: '',
        formLoading: false,
        defaultForm: {
            id: '',
            name: '',
            address_one: '',
            address_two: '',
            city: '',
            state: '',
            zipcode: '',
            users: [],
            main_contact: {
                id: '',
                name: '',
            },
        },
        formData: {},
    }),
    created() {
        this.formData = { ...this.defaultForm, ...this.ticket }
    },
    computed: {
        main_contact_id: {
            // this feels wrong, but it works...
            // getter
            get: function() {
                if (!this.ticket.main_contact) {
                    return ''
                } else {
                    if (this.ticket.main_contact.id !== '') {
                        return this.ticket.main_contact.id
                    } else {
                        return ''
                    }
                }
            },
            // setter
            set: function(newValue) {
                this.formData.main_contact = { id: newValue }
            },
        },
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.isValid = true
                this.saveForm()
            }
        },
        reset() {
            this.$refs.form.reset()
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        updateTicket() {
            this.saveForm()
        },
        createTicket() {
            this.saveForm()
        },
        saveForm() {
            this.formLoading = true
        },
    },
    watch: {
        ticket: {
            handler: function() {
                this.formData = { ...this.defaultForm, ...this.ticket }
            },
            deep: true,
        },
    },
}
</script>
